<template>
  <div>
    <el-drawer
      :title="title"
      :before-close="handleClose"
      :visible.sync="dialog"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
      size="40%"
    >
      <div>
        <el-form :model="addForm" label-width="100px">
          <el-row :gutter="21">
            <el-form-item label="软件系统" label-width="80px" prop="type">
              <el-select
                v-model="addForm.productSource"
                placeholder="请选择"
                :disabled="editShow"
                @change="getSystem"
              >
                <el-option
                  v-for="item in systemFromList"
                  :key="item.uniqueCode"
                  :label="item.name"
                  :value="item.uniqueCode"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-row>

          <el-row :gutter="21">
            <el-form-item label="类型" label-width="80px" prop="type">
              <!-- 1.目录,2.菜单,3按钮,4.接口,5,静态资源 -->
              <el-radio-group v-model="addForm.type" prop="type" :disabled="editShow">
                <!-- 菜单类型1.目录,2.菜单,3按钮,4.接口,5,静态资源,6权限,7.自定义,8.导航菜单 -->
                <el-radio :label="1">目录</el-radio>
                <el-radio :label="2">菜单</el-radio>
                <el-radio :label="3">按钮</el-radio>
                <el-radio :label="4" >接口</el-radio>
                <el-radio :label="5" >静态资源</el-radio>
                <el-radio :label="6" >权限</el-radio>
                <el-radio :label="7" >自定义</el-radio>
                <el-radio :label="8" >导航菜单</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-row>

          <el-row :gutter="21">
            <el-form-item label="名称" label-width="80px">
              <el-input v-model="addForm.name" width="30px"></el-input>
            </el-form-item>
          </el-row>
          <el-row :gutter="21">
            <el-col :span="12">
              <el-form-item label="路径" label-width="80px" prop="code" v-if="addForm.type==2">
                <el-input v-model="addForm.path" width="30px" :disabled="editShow" v-if="!editShow">
                  <template slot="prepend">{{this.codeSign}}</template>
                </el-input>
                <span v-else>{{addForm.path}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="addForm.type==2">
              <el-form-item label="组件地址" label-width="80px">
                <el-input v-model="addForm.component" width="30px"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row v-if="addForm.type==2||addForm.type==3">
            <el-form-item label="上级菜单" prop="parentCode">
              <el-cascader
                :popper-append-to-body="false"
                :disabled="editShow"
                size="medium"
                clearable
                v-model="addForm.parentCodeList"
                :options="menuOptionList"
                :props="{ value: 'code', label: 'name',checkStrictly: true}"
                @change="handleChange"
                ref="cascader"
              ></el-cascader>
            </el-form-item>
          </el-row>
          <el-row :gutter="21">
            <el-col :span="12">
              <el-form-item label="排序" label-width="80px">
                <el-input-number v-model="addForm.sort" width="30px"></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="addForm.type==3">
              <el-form-item label="属性参数" label-width="80px">
                <el-input v-model="addForm.typeParam" width="30px" />
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="addForm.type==2">
              <el-form-item label="是否公共菜单" prop="isCommon">
                <el-switch
                  v-model="addForm.isCommon"
                  active-color="#ff4949"
                  inactive-color="#13ce66"
                  active-text="否"
                  inactive-text="是"
                  :active-value="2"
                  :inactive-value="1"
                ></el-switch>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="21" v-if="addForm.type==4">
            <el-form-item label="api域名" label-width="80px">
              <el-input v-model="addForm.apiRealmName" width="30px"></el-input>
            </el-form-item>
          </el-row>
          <el-row :gutter="21" v-if="addForm.type==4">
            <el-form-item label="api分类" label-width="80px">
              <el-input v-model="addForm.apiType" width="30px"></el-input>
            </el-form-item>
          </el-row>

          <el-row :gutter="21" v-if="addForm.type==4">
            <el-form-item label="api路径" label-width="80px">
              <el-input v-model="addForm.apiPath" width="30px"></el-input>
            </el-form-item>
          </el-row>

          <el-row :gutter="21" v-if="addForm.type==4">
            <el-form-item label="api方式" label-width="80px">
              <el-input v-model="addForm.apiMethod" width="30px"></el-input>
            </el-form-item>
          </el-row>

          <el-row :gutter="21" v-if="addForm.type==2">
            <el-form-item label="图标" label-width="80px">
              <el-input v-model="addForm.icon" width="30px"></el-input>
            </el-form-item>
          </el-row>

          <el-row :gutter="21">
            <el-form-item label="备注" label-width="80px">
              <el-input v-model="addForm.remark" width="30px"></el-input>
            </el-form-item>
          </el-row>
          <el-row :gutter="21" v-if="addForm.type==2">
            <el-col :span="12">
              <el-form-item label="位置" label-width="80px">
                <el-radio-group v-model="addForm.position">
                  <el-radio label="left">左侧</el-radio>
                  <el-radio label="top">顶部</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="打开方式" label-width="80px" prop="openType">
                <el-radio-group v-model="addForm.openType">
                  <el-radio :label="1">页面</el-radio>
                  <el-radio :label="2">弹框</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="21" v-if="addForm.type==6">
            <el-form-item label="授权策略" label-width="80px">
              <el-input v-model="addForm.permsTactics" width="30px"></el-input>
            </el-form-item>
          </el-row>
          <el-row :gutter="21" v-if="addForm.type==6">
            <el-form-item label="权限标识" label-width="80px">
              <el-input v-model="addForm.perms" width="30px"></el-input>
            </el-form-item>
          </el-row>

          <el-row :gutter="21">
            <el-col :span="12">
              <el-form-item label="状态" label-width="80px" prop="status">
                <el-radio-group v-model="addForm.status">
                  <el-radio :label="1">未开发</el-radio>
                  <el-radio :label="2">正常</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="是否隐藏" label-width="80px" prop="isHide">
                <el-radio-group v-model="addForm.isHide">
                  <el-radio :label="0">正常</el-radio>
                  <el-radio :label="1">隐藏</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="21">
            <el-col :span="12">
              <el-form-item label="是否刷新" label-width="80px" prop="status">
                <el-radio-group v-model="addForm.isRefresh">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="2">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="是否缓存" label-width="80px" prop="isHide">
                <el-radio-group v-model="addForm.noCache">
                  <el-radio :label="0">是</el-radio>
                  <el-radio :label="1">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="cancelForm">取 消</el-button>
          <!-- @click="$refs.drawer.closeDrawer()" -->
          <el-button
            type="primary"
            @click="addFormButton()"
            :loading="loading"
          >{{ loading ? '提交中 ...' : '确 定' }}</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import {
  treeShapeList,
  treeMenuShapeListApi,
  addMenu,
  selectById,
  editMenu
} from "@/api/system/systemCommon/menu.js";
import { listproductApi } from "@/api/system/product/product.js";
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      editShow: false,
      title: null,
      addForm: {
        type: 2,
        name: "",
        code: "",
        status: 2,
        isHide: 0,
        position: "left",
        openType: 2,
        isRefresh: 1,
        noCache: 1,
        parentCode: "-1",
        isCommon: 2
      },
      timer: null,
      formLabelWidth: "40px",
      menuType: "",
      menuOptionList: [],
      systemFromList: [],
      codeSign: null,
      productSourcePrent: null
    };
  },
  methods: {
    show(id, type, productSource) {
      this.dialog = true;
      if (type == 2) {
        this.productSourcePrent = productSource;
        this.menuOptionButtonList();
        this.selectByIdButton(id);
        this.editShow = true;
        this.title = "编辑";
      }
      if (type == 1) {
        this.getSystemList();
        this.title = "新增";
        this.codeSign =
          "/" + window.sessionStorage.getItem("systemSource") + "-";
      }
    },
    handleClose(done) {
      if (this.loading) {
        return;
      }
      this.$confirm("关闭")
        .then(_ => {
          this.cancelForm();
          this.dialog = false;
        })
        .catch(_ => {});
    },
    cancelForm() {
      this.loading = false;
      this.dialog = false;
      this.editShow = false;
      this.addForm = {
        type: 2,
        name: "",
        code: "",
        parentCode: "-1",
        status: 2,
        isHide: 0,
        position: "left",
        openType: 2
      };
      this.$parent.menuListButton();
    },
    addFormButton() {
      if (this.addForm.id == undefined || this.addForm.id == null) {
        this.addForm.id;
        if (this.addForm.type == 2) {
          this.addForm.path = this.codeSign + this.addForm.path;
        }
        addMenu(this.addForm).then(res => {
          this.cancelForm();
        });
      } else {
        editMenu(this.addForm).then(res => {
          this.cancelForm();
        });
      }
    },
    //获取上级菜单
    menuOptionButtonList() {
      treeMenuShapeListApi({
        typeList: "1,2",
        productSource: this.productSourcePrent
      }).then(res => {
        this.menuOptionList = res.result;
      });
    },
    //选择上级菜单
    handleChange(value, type) {
      this.addForm.parentCode = this.$refs[
        "cascader"
      ].getCheckedNodes()[0].value;
    },
    //查询详情
    selectByIdButton(id) {
      selectById(id).then(res => {
        this.addForm = res.result;
        this.addForm.type = res.result.type;
        this.addForm.parentCodeList = res.result.parentCode;
      });
    },
    getSystemList() {
      listproductApi().then(res => {
        this.systemFromList = res.result.records;
      });
    },
    getSystem(val) {
      this.productSourcePrent = val;
      this.codeSign = "/" + val + "-";
      this.menuOptionButtonList();
    }
  }
};
</script>

<style lang="less" scoped>
.el-cascader-panel {
  height: 300px;
}
</style>