<template>
  <div>
    <!-- 导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>生活管理</el-breadcrumb-item>
      <el-breadcrumb-item>居家管理</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片试图 -->
    <el-card>
      <el-row :gutter="20">
        <el-col :span="7">
          <span class="demonstration">系统名称：</span>
          <el-select v-model="queryMenuFrom.productSource" placeholder="请选择系统">
            <el-option
              v-for="item in systemList"
              :key="item.uniqueCode"
              :label="item.name"
              :value="item.uniqueCode"
            ></el-option>
          </el-select>
          <!-- <el-date-picker type="month" placeholder="选择月"></el-date-picker> -->
        </el-col>
        <el-col :span="4">
          <el-button type="primay" @click="menuListButton()">查询</el-button>
          <el-button type="primay">重置</el-button>
        </el-col>
      </el-row>
      <!-- 按钮区 -->
      <el-row :gutter="20" style="margin:9px 2px">
        <el-button type="warning" icon="el-icon-add" size="min" @click="addMenuButton">新增</el-button>
      </el-row>
      <!-- 列表区域 -->
      <el-table
        :data="menuDataList"
        border
        row-key="id"
        stripe
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
      >
        <el-table-column label="序号" align="center" width="100px">
          <template slot-scope="scope">
            <el-radio
              :label="scope.$index"
              v-model="radio"
              @change.native="getCurrentRow(scope.row)"
            ></el-radio>
          </template>
        </el-table-column>
        <el-table-column label="归属系统" prop="productSource" />
        <el-table-column label="菜单名称" prop="name" />
        <el-table-column label="菜单图标" prop="icon" />
        <el-table-column label="菜单编码" prop="code" />
        <el-table-column label="菜单类型" prop="type" :formatter="menuStatusEnum">
          <template slot-scope="scope">
            <el-tag :type="tableRowClassName(scope.row.type)">{{scope.row.type}}</el-tag>
          </template >
        </el-table-column  >
        <el-table-column label="菜单路径" prop="path" />
        <el-table-column label="组件路径" prop="component" />
        <el-table-column label="可见" prop="isHide" />
        <el-table-column label="状态" prop="status" :formatter="menuStatusEnum" />
        <el-table-column label="排序" prop="sort" />
        <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
          width="120"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="editMenu(scope.$index,scope.row)"
            >修改</el-button>
            <el-button size="mini" type="text" icon="el-icon-edit" disabled>详情</el-button>
            <el-button size="mini" type="text" icon="el-icon-edit" disabled>删除</el-button>
            <el-button size="mini" type="text" icon="el-icon-edit" disabled>添加下级</el-button>
            <el-button size="mini" type="text" icon="el-icon-edit" disabled>数据规则</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <addMenu ref="addModules"></addMenu>
  </div>
</template>

<script>
import {
  treeShapeList,
  treeMenuShapeListApi
} from "@/api/system/systemCommon/menu.js";
import {
  listByUser,
  listDataProductApi
} from "@/api/system/product/product.js";
import addMenu from "./modules/addMenu";
export default {
  name: "menuList",
  components: {
    addMenu: addMenu
  },
  data() {
    return {
      radio: null,
      menuDataList: [
        {
          recodeMonth: "1"
        }
      ],
      queryMenuFrom: {
        // systemSource: "xunCoupon"
        // systemSource: "xunMy"
      },
      systemList: [],
      systemSource: "xunCommon",
      statusOption: [],
      typeOption:[]
    };
  },
  created() {
    this.menuListButton();
    this.listByUserButton();
    this.initDict();
  },
  methods: {
    editMenu(index, row) {
      this.$refs.addModules.show(row.id, 2, row.productSource);
    },
    menuListButton() {
      treeMenuShapeListApi(this.queryMenuFrom).then(res => {
        this.menuDataList = res.result;
      });
    },
    addMenuButton() {
      this.$refs.addModules.show(null, 1, null);
    },

    listByUserButton() {
      (this.queryMenuFrom.attribute = 1),
        listDataProductApi(this.queryMenuFrom).then(res => {
          this.systemList = res.result;
        });
    },
    tableRowClassName(row) {
      if (row === 2) {
        return "success";
      }
    },
    menuStatusEnum(row, column) {
      return this.selectDictLabel(this.statusOption, row.status);
    },
    initDict() {
      this.selectDictItemSyn(this.systemSource, "common_status").then(
        response => {
          this.statusOption = response.result;
        }
      );
    }
  }
};
</script>

<style lang="less" scoped>
</style> 